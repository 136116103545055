import React from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';
import {Routes, Route} from 'react-router-dom';
import { useMemo } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { themeSettings } from './theme';
import Navbar from './components/common/Navbar';
import HomeScreen  from './components/screens/HomeScreen';
import LoginScreen from './components/screens/LoginScreen';
import RegisterScreen from './components/screens/RegisterScreen';
import PricingScreen from './components/screens/PricingScreen';
import ForgotPasswordScreen from './components/screens/ForgotPasswordScreen';
import ResetPasswordScreen from "./components/screens/ResetPasswordScreen";
import VerifyEmailScreen from "./components/screens/VerifyEmailScreen";
import WaitlistScreen from './components/screens/WaitlistScreen';
import CompaniesScreen from './components/screens/CompaniesScreen';
import CompanyScreen from './components/screens/CompanyScreen';
import SuccessScreen from './components/screens/SuccessScreen';
import ProfileScreen from './components/screens/ProfileScreen';
import FeatureRequestScreen from './components/screens/FeatureRequestScreen';
import PrivateRoute from './components/routing/PrivateRoute';

function App() {
  const theme = useMemo(() => createTheme(themeSettings()), []);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <Navbar/> */}
        <Routes>
          <Route exact path="/" element={<PrivateRoute><WaitlistScreen /></PrivateRoute>} />
          {/* <Route exact path="/login" element={<LoginScreen />} />
          <Route exact path="/register" element={<RegisterScreen />} />
          <Route exact path="/pricing" element={<PricingScreen />} />
          <Route exact path="/forgot-password" element={<ForgotPasswordScreen />} />
          <Route exact path="/passwordreset/:resetToken" element={<ResetPasswordScreen/>} />
          <Route exact path="/verify-email/:verifyToken" element={<VerifyEmailScreen/>} />
          <Route exact path="/waitlist" element={<WaitlistScreen/>} />
          <Route exact path="/companies" element={<CompaniesScreen/>} />
          <Route exact path="/companies/:id" element={<CompaniesScreen/>} />
          <Route exact path="/company/:id" element={<CompanyScreen/>} />
          <Route exact path="/success" element={<SuccessScreen/>} />
          <Route exact path="/profile" element={<ProfileScreen/>} />
          <Route exact path="/feature-request" element={<FeatureRequestScreen/>} /> */}
        </Routes>
      </ThemeProvider>
    </div>
  );
}

//export default App;
export default withAITracking(reactPlugin, App);