import React from 'react' ;
import { Box, Typography, useMediaQuery, Collapse, Alert, InputAdornment, Button, Divider, TextField } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

const WaitlistScreen = () => { 
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleWaitlist = async (e) => {
        e.preventDefault();
        //console.log(name, email);
        try {
            const res = await axios.post("/api/waitlist/join", {name, email});
            if (res.data) {
                //set success true for 3 seconds
                setSuccess(true);
                setError(false);
                setTimeout(() => {setSuccess(false);}, 3000);
            }
        } catch (err) {
            //console.log(err);
            if (err.response.data.error === "A user with this email has already signed up for the waitlist.")
                setError(err.response.data.error);
            else
                setError("Something went wrong. Please try again later.");
            setSuccess(false);
            setTimeout(() => {setError("");}, 3000);
        }
    }



    return (
        <Box width={isNotMobile ? "80%" : "92%" } 
            p="2rem" 
            m="2rem auto" 
            sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}
        >
            <Box>
                <Divider/>
                <Typography textAlign="center" fontSize={15} fontWeight="400" mt={2} color="gray">10kDecoder | Financial Filings Simplified</Typography>
            </Box>
            
            <Box width={isNotMobile ? "60%" : "95%"}>
                <Typography textAlign="center" variant={isNotMobile ? "h1" : "h3"} mt={5} mb={2} fontWeight="400">Know what you own with 10kDecoder!</Typography>
                
                <Typography fontSize={isNotMobile ? 18 : 15} fontWeight={300} my={2} textAlign="center">
                Are you tired of feeling confused about your investments? Do you wish you had a better understanding of what you own and where your money is invested? 
                Do you find yourself overwhelmed by lengthy 10Ks? Are you tired of trying to make sense of complex financial jargon? Don't let financial data overwhelm 
                you any longer! 10kDecoder is the ultimate tool for transforming financial filings into actionable insights.
                </Typography>   
                <Typography fontSize={isNotMobile ? 18 : 15} fontWeight={300} my={2} textAlign="center">
                With 10kDecoder, you can quickly and easily access key information from financial filings and gain a deeper understanding of a company's financial health. 
                Whether you're a seasoned investor or just starting out, 10kDecoder is the perfect tool to help you make informed decisions about your investments. 
                Our platform provides you with the insights and information you need to stay ahead of the curve and identify new investment opportunities. 
                Our user-friendly interface makes it easy to access key information, saving you time and hassle.
                </Typography>

                <Typography textAlign="center" variant={isNotMobile ? "h1" : "h3"} mt={4} fontWeight="600" color="primary">Join the waitlist</Typography>

            </Box>

            <Box width={isNotMobile ? 470 : 240} mt={isNotMobile ? 4 : 2}>
                <Collapse in={success}>
                    <Alert severity="success" sx={{mb: 2}}>You have successfully joined the waitlist!</Alert>
                </Collapse>
                <Collapse in={error.length > 0}>
                    <Alert severity="error" sx={{mb: 2}}>{error}</Alert>
                </Collapse>
                <form onSubmit={handleWaitlist}>
                <TextField fullWidth placeholder="Full name" required value={name} onChange = {(e) => setName(e.target.value)}
                    sx={{".MuiOutlinedInput-root": {  borderRadius: 3, boxShadow:1 }, mb:2}} size={isNotMobile ? "medium" : "small"}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <PersonOutlineOutlinedIcon />
                        </InputAdornment>
                        ),
                    }}
                />
                <TextField fullWidth placeholder="Email address" required value = {email} size={isNotMobile ? "medium" : "small"} onChange = {(e) => setEmail(e.target.value)}
                    sx={{".MuiOutlinedInput-root": {  borderRadius: 3, boxShadow:1 }}}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <EmailOutlinedIcon />
                        </InputAdornment>
                        ),
                    }}
                />
                <Button variant="contained" fullWidth disableElevation endIcon={<ArrowForwardRoundedIcon/>} type="submit"
                sx={{mt: isNotMobile ? 4 : 3, textTransform: 'none', fontSize: isNotMobile ? 16 : 13, py:1.2, bgcolor: (name && email) ? "primary.main" : "primary.light" }}>
                    Join the waitlist
                </Button>
                </form>
            </Box>
            
            

           
        </Box>
    )
}

export default WaitlistScreen;
