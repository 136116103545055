// SuccessMessage.js
import React, { useEffect } from 'react';
import { Typography, Container, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const SuccessScreen = () => {
  const navigate = useNavigate();
  const isNotMobile = useMediaQuery("(min-width: 1000px)");

  useEffect(() => {

    const checkSub = (data) => {
      if (data.subscription) {
          localStorage.setItem("sub", data.subscription);
      } else {
          localStorage.removeItem("sub");
      }
    }

    const logoutHandler = async () => { 
      await axios.post("/api/auth/logout").then(res => fullyLogout(res.data));
    }

    const fullyLogout = (data) => { 
        if (data.success) {
            localStorage.removeItem("authToken");
            localStorage.removeItem("sub");
            window.location.reload();
        }
    }

    
    //wait 5 seconds
    const tokenHandler = async () => {
        const token = await axios.get("/api/auth/refresh-token");
        if (token.data === "Unauthorized") {
            localStorage.removeItem("authToken");
            localStorage.removeItem("sub");
            logoutHandler();
        } else {
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                await axios.get("/api/auth/subscription", config).then(res => checkSub(res.data));
            }
        }
    }
    tokenHandler();

  }, [navigate]);

  return (
    <Container sx={{display: "flex", flexDirection: "column", alignItems:"center", justifyContent: "flex-start", height: "100vh", paddingTop: isNotMobile ? "20vh" : "10vh"}}>
      <Typography sx={{fontWeight: "bold", 
                        fontSize: isNotMobile ? "2rem" : "1rem", 
                        color: "#4caf50", 
                        border:  isNotMobile ? "3px solid #4caf50" : "2px solid #4caf50",
                        padding: "20px", 
                        borderRadius: isNotMobile ? "10px" : "6px",
                        textAlign: "center"
                    }}
    >
        Success! You will be redirected within 10 seconds.
      </Typography>
    </Container>
  );
};

export default SuccessScreen;
